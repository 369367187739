<template>
  <v-card
    max-height="450"
    max-width="250"
    class="playcard elevation no-padding"
    @click="navigateToNFT"
  >
    <div v-if="loading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="mx-auto text-center">
        <!-- Updated image source -->
        <img class="songImage" :src="nftDetails.image" alt="NFT" />
      </div>
      <div class="pl-4 pr-1 pb-3" style="width: 100%">
        <div class="d-flex my-1" style="gap: 5px">
          <img
            class="artistImage"
            src="/GUMMINT.png"
            alt="Gummint"
          />
          <p class="text-white subText mt-2" style="opacity: 0.6">
            {{ nftDetails.artist1 || 'Gummint' }}
          </p>
        </div>
        <!-- Display name property -->
        <p class="text-white titleText truncate">{{ nftDetails.name }}</p>
        <!-- Display royalty information -->
        <p class="text-white subText" style="opacity: 0.8">
          {{ nftDetails.royaltyType }} share: {{ nftDetails.royaltyPercentage }}%
        </p>
      </div>
      <v-card-actions class="d-flex justify-end" style="padding: 0; margin-bottom: -5px;">
        <v-btn block class="btn text-center">
          <!-- Updated market data check -->
          <span v-if="nftDetails.marketData?.isListed">
            {{ formatPrice(nftDetails.marketData.price || '0') }} MATIC
          </span>
          <span v-else>Sold</span>
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ethers } from "ethers";

export default {
  name: "NFTCard",
  
  props: {
    nftData: {
      type: Object,
      required: true,
    },
    collectionId: {
      type: [String, Number],
      required: true
    }
  },

  setup(props) {
    const router = useRouter();
    const loading = ref(false);
    const nftDetails = ref(props.nftData);

    console.log("NFT Data received:", props.nftData); // Debug log

    const formatPrice = (price) => {
      if (!price) return '0';
      try {
        // First handle scientific notation if present
        const normalizedPrice = price.toString();
        if (normalizedPrice.includes('e')) {
          return parseFloat(normalizedPrice).toString();
        }
        // Then format the price
        return ethers.formatEther(normalizedPrice);
      } catch (error) {
        console.error("Error formatting price:", error);
        return '0';
      }
    };

    const navigateToNFT = () => {
      router.push({
        path: `/collection/${props.collectionId}/${props.nftData.tokenId}/${props.nftData.tokenId}`,
      });
    };

    return {
      loading,
      navigateToNFT,
      formatPrice,
      nftDetails
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.elevation {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.no-padding {
  padding: 0 !important;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 250px;
}

.elevation:hover {
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}

.playcard {
  background: #0d0d0d;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(10px);
}

.subText {
  font-size: 15px;
}

.songImage {
  height: 180px;
  width: 180px;
  object-fit: cover;
}

.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

.btn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-top: 5px;
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.artistImage {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 170px;
    max-height: 320px;
  }
  
  .mainProgressCircle {
    height: 310px;
    width: 170px;
  }

  .songImage {
    height: 160px;
    width: 160px;
  }

  .subText {
    font-size: 12px;
  }

  .titleText {
    font-size: 14px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 250px;
  }
}
</style>