<template>
  <div class="background">
    <div class="banner-background"></div>
    
    <div class="container">
      <!-- <v-img
        class="logoContainer mx-auto"
        src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
        alt="logo"
        max-height="80"
        max-width="80"
        contain
      >
      </v-img> -->
      <v-row justify="center" align="center" class="mx-auto rowHeight">
        <v-col cols="12" md="6">
          <div class="titleSection" data-aos="fade-up">
            <h1 class="mb-3 titleText">INVEST IN YOUR FAVOURITE MUSIC</h1>
            <p class="subtitleText">
              Own a Piece of Music History: Earn Streaming Royalties by
              Investing in Songs You Love
            </p>
            <div class="mt-5 button-group">
              <v-btn
                class="buyBtn pb-10 pt-2 my-2"
                @click="navigateToCollection"
                >Invest in music</v-btn
              >
              <!-- <v-btn
                class="visitBtn px-6 mt-2 pb-10 pt-2"
                @click="navigateToArtistRegister"
                >Join as artist</v-btn
              > -->
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(song, index) in nftSongs"
                :key="index"
              >
                <PlayCard :card="song" />
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PlayCard from "./PlayCard.vue";
import { ref, onMounted, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import router from "../router";
import Swiper from "swiper";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

export default {
  name: "BannerSection",
  components: {
    PlayCard,
  },
  setup() {
    const nftSongs = ref([
  {
    id: "1",
    collection: {
      id: "1",
      name: "Enter the metaverse",
      image: "/images/enter-the-metaverse-cover.png",  // Update path to your actual image in public folder
      link: "https://www.youtube.com/embed/5qFr76kgALQ",
      genre: "Deep house",
      artist: {
        id: "1",
        name: "Gummint"
      },
      featuredArtists: [
        { name: "Hypaphonik" },
      ]
    }
  },
  {
    id: "2",
    collection: {
      id: "2",
      name: "Ungathemba",
      image: "/images/ungathemba-cover.png",  
      link: "https://www.youtube.com/embed/b2DjzEnDJpA",
      genre: "Afro pop",
      artist: {
        id: "1",
        name: "Gummint"
      },
      featuredArtists: [
        { name: "Belo Salo" }
      ]
    }
  },
  {
    id: "3",
    collection: {
      id: "3",
      name: "Immutable",
      image: "/images/immutable-cover.png",  // Update path to your actual image
      link: "https://www.youtube.com/embed/yZwbJp7S7Fo",
      genre: "Deep house",
      artist: {
        id: "1",
        name: "Gummint"
      },
      featuredArtists: [
        { name: "Wapojije" },
      ]
    }
  }
]);

    onMounted(() => {
      new Swiper(".swiper-container", {
        modules: [EffectCoverflow, Navigation],
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        initialSlide: 2,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
          slideShadows: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    });

    function navigateToCollection() {
      router.push({ path: `/collection/1` });
    }

    function navigateToArtistRegister() {
      router.push({ path: `/authentication/register_artist` });
    }

    return {
      navigateToCollection,
      navigateToArtistRegister,
      nftSongs,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.background {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 1;
}

.logoContainer {
}

.titleText {
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subtitleText {
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255);
  margin-bottom: 2rem;
}

.button-group {
  display: flex;
  gap: 1rem;
}

.buyBtn,
.visitBtn {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  text-transform: none;
  transition: all 0.3s ease;
}

.buyBtn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 210px;
  border-color: white;
  border-width: 2px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  /* background-image: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%); */
}

.visitBtn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  width: 200px;
  text-align: center;
  border-color: white;
  border-width: 2px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  background: rgb(17, 169, 235);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.buyBtn:hover,
.visitBtn:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 400px;
  transition: all 0.3s ease;
}

.swiper-slide-active {
  transform: scale(1.1);
  z-index: 2;
}

.swiper-slide-prev,
.swiper-slide-next {
  filter: blur(2px);
  opacity: 0.7;
}

.swiper-slide:not(.swiper-slide-active):not(.swiper-slide-prev):not(
    .swiper-slide-next
  ) {
  filter: blur(4px);
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .titleText {
    font-size: 3.5rem;
  }
}

@media (max-width: 960px) {
  .titleText {
    font-size: 3rem;
  }
  .subtitleText {
    font-size: 1rem;
  }
  .button-group {
    flex-direction: column;
  }
  .swiper-slide {
    width: 250px;
    height: 350px;
  }
}

@media (max-width: 600px) {
  .titleText {
    font-size: 2.5rem;
  }
  .swiper-slide {
    width: 200px;
    height: 280px;
  }
}
</style>
