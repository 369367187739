<template>
  <v-main class="mainContainer">
    <div v-if="isMainLoading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>

    <div v-if="!isMainLoading">
      <div class="contentContainer mb-15">
        <div
          class="backgroundGradient"
          :style="`background: ${bannerBackground}`"
        >
          <v-container class="mt-10">
            <v-row
              class="mx-auto"
              justify="center"
              align="center"
              style="max-width: 1200px"
            >
              <v-col>
                <v-card
                  max-height="400"
                  max-width="400"
                  class="playcard"
                  color="transparent"
                >
                  <div class="text-center">
                    <img class="songImage" :src="metadata.image" alt="logo" />
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <p class="text-white text-h2">
                  {{ metadata.name }}
                </p>
                <p class="text-white text-h6">
                  {{ metadata.collectionGenre }}
                </p>

                <p class="text-white subText mt-1">
                  By: {{ metadata.artist1 }}
                </p>

                <p class="text-white subText mt-1">
                  {{ metadata.royaltyType }} royalty:
                  {{ metadata.royaltyPercentage }}%
                </p>
                <p class="text-white subText mt-1">
                  Life of rights: {{ metadata.lifeOfRights }}
                </p>
                <p class="text-white subText mt-1">
                  Edition:
                  {{
                    metadata.attributes && metadata.attributes.length > 0
                      ? metadata.attributes.find(
                          (attr) => attr.trait_type === "Edition"
                        )?.value || "N/A"
                      : "N/A"
                  }}
                </p>
                <p class="text-white subText mb-6 mt-1">
                  Song released: {{ metadata.songReleaseDate }}
                </p>

                <div v-if="isOwner" class="mt-1">
                  <p class="text-white text-h6">
                    Asset Owner
                    <v-icon color="blue" size="25" class="mr-1 ownerIcon"
                      >mdi-check-decagram</v-icon
                    >
                  </p>
                  <v-btn
                    v-if="!isListedForSale"
                    size="large"
                    class="buyBtn px-4 mt-5"
                    :disabled="buyloading"
                    @click="showModal = true"
                  >
                    SELL THIS ASSET
                    <v-progress-circular
                      v-if="buyloading"
                      class="ml-2"
                      indeterminate
                      color="white"
                      :size="25"
                    ></v-progress-circular>
                  </v-btn>
                </div>
                <div v-else>
                  <p class="text-white text-h5 mb-6 mt-1">
                    Price: {{ formatPrice(nftMarketplaceDetails.price) }} MATIC
                  </p>
                  <v-btn
                    v-if="isListedForSale && isSeller && isAuthenticated"
                    size="large"
                    class="buyBtn px-4 mt-5 mx-auto"
                    @click="cancelListing"
                    :disabled="cancelLoading"
                  >
                    Cancel sale
                    <v-progress-circular
                      v-if="cancelLoading"
                      class="ml-2"
                      indeterminate
                      color="white"
                      :size="25"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn
                    v-else-if="isListedForSale && isAuthenticated"
                    size="large"
                    class="buyBtn px-4 mt-5 mx-auto"
                    @click="buyNFT"
                    :disabled="buyloading"
                  >
                    BUY ASSET
                    <v-progress-circular
                      v-if="buyloading"
                      class="ml-2"
                      indeterminate
                      color="white"
                      :size="25"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn
                    v-else-if="!isAuthenticated"
                    size="large"
                    class="buyBtn px-4 mt-5 mx-auto"
                    @click="redirectToLogin"
                  >
                    BUY ASSET
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <v-card
          max-width="1200"
          class="mx-auto detailsTab mt-10"
          flat
          color="transparent"
        >
          <v-tabs
            centered
            center-active
            color="rgb(255, 123, 0)"
            style="font-size: 22px"
            v-model="tab"
          >
            <v-tab class="tabStyle">Details</v-tab>
            <v-tab class="tabStyle">Streaming</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div v-if="tab == 0">
                <div v-if="mainArtist">
                  <div class="text-white detailsContainer mt-6">
                    <h2 class="text-h5 mb-3 subHeading">Artists</h2>
                    <div>
                      <v-row class="artistContainer">
                        <v-col>
                          <div class="d-flex custom-link" style="gap: 10px">
                            <img
                              :src="mainArtist.profilePicture"
                              alt="Artist image"
                              class="artistImage"
                            />
                            <h3 class="text-h6 pb-2">
                              {{ mainArtist.name }}
                            </h3>
                          </div>
                        </v-col>
                      </v-row>
                      <p class="mb-6">
                        {{ mainArtist.description }}
                      </p>
                    </div>
                    <div v-for="artist in featuredArtists" :key="artist.id">
                      <v-row class="artistContainer">
                        <v-col>
                          <div class="d-flex custom-link" style="gap: 10px">
                            <img
                              :src="artist.profilePicture"
                              alt="Artist image"
                              class="artistImage"
                            />
                            <h3 class="text-h6 pb-2">
                              {{ artist.name }}
                            </h3>
                          </div>
                        </v-col>
                      </v-row>
                      <p class="mb-6">
                        {{ artist.description }}
                      </p>
                    </div>
                    <h3 class="text-h5 my-3 subHeading">Ownership Benefits</h3>
                    <p class="mb-6">
                      By owning an "{{ metadata.name }}" NFT, you not only
                      support the song but also earn
                      {{ metadata.royaltyPercentage }}% share of the streaming
                      royalties every time the song is played. As more people
                      listen to the song, the value of your NFT may increase.
                      Additionally, NFT holders can possibly receive exclusive
                      access and deals to virtual meet-and-greets, events and
                      merchandise.
                    </p>
                    <h3 class="text-h5 my-3 subHeading">Streaming Platforms</h3>
                    <p>Available on all streaming platforms</p>
                    <iframe
                      class="songPlayer mt-3"
                      :src="songLink"
                      :title="metadata.name"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>

                    <h3 class="text-h5 my-3 subHeading">
                      Terms and Conditions
                    </h3>
                    <p class="mb-6">
                      By buying this NFT you accept that you have read and
                      agreed to our
                      <router-link class="text-blue" to="/terms">
                        terms and conditions</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="tab == 1">
                <v-container class="mt-10">
                  <!-- <EarningsIndicator
                    :ownershipPercentage="metadata.royaltyPercentage"
                    :totalEarnings="totalEarnings"
                    :totalStreams="totalStreams"
                  /> -->
                  <p class="text-white text-h4 mb-8">Payout Calculator</p>
                  <p class="text-white text-h5 mb-3">
                    Enter your streaming royalty percentage to calculate
                    earnings per stream. Specify the number of streams to
                    estimate your total earnings.
                  </p>
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12" md="8">
                      <v-card class="glassmorphism text-white">
                        <v-card-text class="mx-auto text-center">
                          <div class="mx-auto">
                            <v-text-field
                              class="mx-10"
                              :clearable="true"
                              variant="underlined"
                              v-model="royaltyPercentage"
                              label="Streaming Royalty Percentage (%)"
                            ></v-text-field>
                          </div>
                          <div class="mx-auto">
                            <v-text-field
                              class="mx-10"
                              :clearable="true"
                              variant="underlined"
                              v-model="numberOfStreams"
                              type="number"
                              label="Number of Streams"
                            ></v-text-field>
                          </div>

                          <v-row
                            v-for="(rate, platform) in streamingRates"
                            :key="platform"
                            align="center"
                            justify="center"
                          >
                            <v-col cols="3">
                              <img
                                :src="getPlatformImage(platform)"
                                alt="Platform Image"
                                class="platform-image"
                              />
                            </v-col>
                            <v-col>
                              <p class="text-h6">{{ platform }}</p>
                            </v-col>
                            <v-col>
                              <p class="text-h6">
                                R {{ calculatePayout(rate) }}
                              </p>
                            </v-col>
                          </v-row>

                          <v-divider class="my-4"></v-divider>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col cols="6">
                                  <p class="text-h5">Total Payout:</p>
                                </v-col>
                                <v-col cols="6">
                                  <p class="text-h5">
                                    R {{ calculateTotalPayout }}
                                  </p>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>

      <BottomFooter class="footer" />
    </div>

    <v-dialog v-model="showModal" width="600">
      <v-card class="rounded-xl glassmorphism elevation-15 text-white">
        <p class="text-h4 text-center mt-3">
          {{ currentTitle }}
        </p>
        <div class="mx-auto">
          <v-img
            :src="metadata.image"
            alt="logo"
            height="200"
            width="200"
            class="mt-4 rounded-lg"
            contain
          ></v-img>
        </div>
        <p class="text-white text-h6 text-center">
          {{ metadata.name }}
        </p>
        <p class="text-white text-subtitle-1 mb-6 mt-1 text-center">
          By: {{ metadata.artist1 }}
        </p>

        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <p class="text-center text-h5">
                You have to first approve this NFT to be listed for sale
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="buyBtn mx-auto px-4"
                :disabled="approveLoading"
                @click="approveNFT"
              >
                Approve
                <v-progress-circular
                  v-if="approveLoading"
                  class="ml-2"
                  indeterminate
                  color="white"
                  :size="23"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-window-item>

          <v-window-item :value="2">
            <div class="pa-4 text-center">
              <p class="modalSubtitleText text-center text-white">
                Put your NFT up for Sale on the market
              </p>
              <v-card-text>
                <v-form class="text-white">
                  <v-text-field
                    v-model="sellPrice"
                    variant="underlined"
                    label="Price (MATIC)"
                    :clearable="true"
                    prepend-icon="mdi-cart-outline"
                    required
                  ></v-text-field>
                  <div class="text-center">
                    <v-btn
                      class="mx-auto buyBtn mb-3"
                      :disabled="relistLoading"
                      @click="listNFT"
                      >List for sale
                      <v-progress-circular
                        v-if="relistLoading"
                        indeterminate
                        color="white"
                        :size="23"
                      ></v-progress-circular>
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { ethers } from "ethers";
import { Magic } from "magic-sdk";
import CreateNft from "../CreateNft.json";
import Marketplace from "../Marketplace.json";
import BottomFooter from "../components/BottomFooter.vue";
import { useAuthenticationStore } from "../stores/authentication";
import { storeToRefs } from "pinia";
import { prominent } from "color.js";
import router from "../router";
import EarningsIndicator from "../components/EarningsIndicator.vue";

// Updated Mock Artist Data
const artistData = {
  1: { // Enter the metaverse collection
    mainArtist: {
      id: 1,
      name: "Gummint",
      description: "A pioneering South African music collective revolutionizing the industry through dynamic collaborations and innovative artist-fan connections.",
      profilePicture: "/GUMMINT.png"
    },
    featuredArtists: [
      {
        id: 2,
        name: "Hypaphonik",
        description: "A visionary deep house producer whose signature sound blends hypnotic rhythms with soulful melodies. Known for crafting immersive soundscapes that captivate dance floors across South Africa, his productions seamlessly merge traditional deep house elements with innovative electronic textures.",
        profilePicture: "/images/hypaphonikpic.jpg"
      }
    ],
    songLink: "https://www.youtube.com/embed/5qFr76kgALQ"
  },
  2: { // Collection 2
    mainArtist: {
      id: 1,
      name: "Gummint",
      description: "South African music collective pushing the boundaries of sound through innovative collaborations.",
      profilePicture: "/GUMMINT.png"
    },
    featuredArtists: [
      {
        id: 3,
        name: "Belo Salo",
        description: "Versatile artist bringing unique vibes to collaborative projects.",
        profilePicture: "/images/belo.jpg"
      },
      {
        id: 4,
        name: "J-6ix",
        description: "Talented artist known for distinctive flow and style.",
        profilePicture: "/images/j6ix.jpg"
      }
    ],
    songLink: "https://www.youtube.com/embed/abcdef12345"
  },
  3: { // Collection 3
    mainArtist: {
      id: 1,
      name: "Gummint",
      description: "South African music collective pushing the boundaries of sound through innovative collaborations.",
      profilePicture: "/GUMMINT.png"
    },
    featuredArtists: [
      {
        id: 5,
        name: "Wapojije",
        description: "Dynamic artist bringing fresh energy to collaborations.",
        profilePicture: "/images/Wapojijepic.jpg"
      }
    ],
    songLink: "https://www.youtube.com/embed/yZwbJp7S7Fo"
  }
};

export default {
  name: "NFTPage",

  components: {
    BottomFooter,
    EarningsIndicator,
  },

  setup() {
    const route = useRoute();
    const nftTokenId = route.params.nftTokenId;
    const collectionId = Number(route.params.id);

    // Loading states
    const loading = ref(false);
    const buyloading = ref(false);
    const relistLoading = ref(false);
    const cancelLoading = ref(false);
    const approveLoading = ref(false);
    const currentNftLoading = ref(false);

    // Data refs
    const nftOwner = ref({});
    const tab = ref(0);
    const nftMarketplaceDetails = ref({});
    const metadata = ref({});
    const sellPrice = ref("");
    const showModal = ref(false);
    const step = ref(1);
    const bannerBackground = ref({});
    const royaltyPercentage = ref(null);
    const numberOfStreams = ref(100000); // Default value
    const totalStreams = ref(500000); // Mock data
    const totalEarnings = ref(25000); // Mock data

    // Get artist data based on collection
    const mainArtist = ref(artistData[collectionId]?.mainArtist || null);
    const featuredArtists = ref(
      artistData[collectionId]?.featuredArtists || []
    );
    const songLink = ref(artistData[collectionId]?.songLink || "");

    // Auth store
    const userAuth = useAuthenticationStore();
    const { accounts, isAuthenticated } = storeToRefs(userAuth);
    const { clearCookies } = userAuth;

    const streamingRates = {
      "Tidal Music": 0.01284,
      "Apple Music": 0.008,
      "Amazon Music": 0.00402,
      Spotify: 0.00318,
      "YouTube Music": 0.002,
      Pandora: 0.00133,
      Deezer: 0.0011,
    };

    const checkLogin = async () => {
      const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);
      try {
        const isLoggedIn = await magic.user.isLoggedIn();
        if (!isLoggedIn) {
          clearCookies();
        }
      } catch {
        // Handle errors if required
      }
    };

    async function getCurrentNFT(token_id) {
      currentNftLoading.value = true;
      try {
        const provider = new ethers.JsonRpcProvider(
          process.env.VUE_APP_RPC_URL
        );
        let NFTcontractData = JSON.parse(CreateNft);
        let market = JSON.parse(Marketplace);
        let nftcontractaddress = NFTcontractData.address;

        const contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );

        nftMarketplaceDetails.value = await contract.getListing(
          parseInt(token_id)
        );

        // Get NFT metadata
        const nftContract = new ethers.Contract(
          nftcontractaddress,
          NFTcontractData.abi,
          provider
        );
        const tokenURI = await nftContract.tokenURI(token_id);
        const response = await fetch(tokenURI);
        const metadataResponse = await response.json();

        // Get NFT owner
        const owner = await nftContract.ownerOf(token_id);
        nftOwner.value = owner;
        metadata.value = metadataResponse;

        // Set banner background color
        const color = await prominent(metadataResponse.image, {
          amount: 3,
          format: "hex",
        });
        bannerBackground.value = color[1];

        currentNftLoading.value = false;
      } catch (error) {
        console.error("Error fetching NFT:", error);
        currentNftLoading.value = false;
      }
    }

    async function approveNFT() {
      if (approveLoading.value) return;

      approveLoading.value = true;
      try {
        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);
        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();

        let createNft = JSON.parse(CreateNft);
        let market = JSON.parse(Marketplace);

        const nftContract = new ethers.Contract(
          createNft.address,
          createNft.abi,
          signer
        );

        const tx = await nftContract.approve(market.address, nftTokenId);
        await tx.wait();

        step.value = 2;
      } catch (error) {
        console.error("Error approving NFT:", error);
        alert("Failed to approve NFT. Please try again.");
      } finally {
        approveLoading.value = false;
      }
    }

    async function listNFT() {
      if (relistLoading.value) return;

      relistLoading.value = true;
      try {
        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);
        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();

        let market = JSON.parse(Marketplace);
        let marketContract = new ethers.Contract(
          market.address,
          market.abi,
          signer
        );

        const price = ethers.parseEther(sellPrice.value);
        const tx = await marketContract.listItem(nftTokenId, price);
        await tx.wait();

        showModal.value = false;
        getCurrentNFT(nftTokenId);
        alert("NFT listed successfully!");
      } catch (error) {
        console.error("Error listing NFT:", error);
        alert("Failed to list NFT. Please try again.");
      } finally {
        relistLoading.value = false;
      }
    }

    async function cancelListing() {
      if (cancelLoading.value) return;

      cancelLoading.value = true;
      try {
        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);
        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();

        let market = JSON.parse(Marketplace);
        let marketContract = new ethers.Contract(
          market.address,
          market.abi,
          signer
        );

        const tx = await marketContract.cancelListing(nftTokenId);
        await tx.wait();

        getCurrentNFT(nftTokenId);
        alert("Listing cancelled successfully!");
      } catch (error) {
        console.error("Error cancelling listing:", error);
        alert("Failed to cancel listing. Please try again.");
      } finally {
        cancelLoading.value = false;
      }
    }

    async function buyNFT() {
      if (buyloading.value) return;

      buyloading.value = true;
      try {
        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);
        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();

        let market = JSON.parse(Marketplace);
        let marketContract = new ethers.Contract(
          market.address,
          market.abi,
          signer
        );

        const tx = await marketContract.buyItem(nftTokenId, {
          value: nftMarketplaceDetails.value.price,
        });

        await tx.wait();
        getCurrentNFT(nftTokenId);
        alert("NFT purchased successfully!");
      } catch (error) {
        console.error("Error buying NFT:", error);
        alert("Failed to purchase NFT. Please try again.");
      } finally {
        buyloading.value = false;
      }
    }

    const calculatePayout = (rate) => {
      const payout =
        (royaltyPercentage.value / 100) * rate * numberOfStreams.value;
      return payout.toFixed(2);
    };

    const calculateTotalPayout = computed(() => {
      return Object.values(streamingRates)
        .reduce((acc, rate) => {
          return acc + parseFloat(calculatePayout(rate));
        }, 0)
        .toFixed(2);
    });

    const getPlatformImage = (platform) => {
      const imageMap = {
        "Tidal Music":
          "/images/tidal.svg",
        "Apple Music":
          "/images/apple.png",
        "Amazon Music":
          "/images/amazon.png",
        Spotify:
          "/images/spotify.png",
        "YouTube Music":
          "/images/Youtube.png",
        Pandora:
          "/images/pandora.png",
        Deezer:
          "/images/deezer.png",
      };
      return imageMap[platform] || "/path/to/default-image.png";
    };

    const isOwner = computed(() => {
      if (nftMarketplaceDetails.value && accounts.value) {
        return nftOwner.value === accounts.value.toLowerCase();
      }
      return false;
    });

    const formatPrice = (price) => {
      if (!price) return "0";
      return ethers.formatEther(price.toString());
    };

    const isSeller = computed(() => {
      if (nftMarketplaceDetails.value && accounts.value) {
        return nftMarketplaceDetails.value.seller === accounts.value;
      }
      return false;
    });

    const isListedForSale = computed(() => {
      return nftMarketplaceDetails.value?.listedForSale || false;
    });

    const isMainLoading = computed(() => {
      return currentNftLoading.value;
    });

    const currentTitle = computed(() => {
      return step.value === 1 ? "List NFT for Sale" : "Set your selling price";
    });

    onMounted(() => {
      window.scrollTo(0, 0);
      checkLogin();
      getCurrentNFT(nftTokenId);
    });

    return {
      // State
      loading,
      buyloading,
      relistLoading,
      cancelLoading,
      approveLoading,
      currentNftLoading,
      nftOwner,
      tab,
      metadata,
      sellPrice,
      showModal,
      step,
      bannerBackground,
      royaltyPercentage,
      numberOfStreams,
      totalStreams,
      totalEarnings,
      mainArtist,
      featuredArtists,
      songLink,
      nftMarketplaceDetails,
      formatPrice,

      // Computed
      isOwner,
      isSeller,
      isListedForSale,
      isMainLoading,
      currentTitle,
      calculateTotalPayout,

      // Methods
      getCurrentNFT,
      listNFT,
      buyNFT,
      approveNFT,
      cancelListing,
      calculatePayout,
      getPlatformImage,

      // Route
      route,

      // Auth
      isAuthenticated,
      accounts,

      // Constants
      streamingRates,

      // Utils
      redirectToLogin: () => router.push({ name: "Login" }),
    };
  },
};
</script>
<style scoped>
.p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.mainContainer {
  margin-top: -150px;
}
.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}
.platform-image {
  width: 60px;
  border-radius: 50%;
  margin-right: 8px;
}
.songPlayer {
  width: 560px;
  height: 315px;
}
.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
}

.contentLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.link-no-style {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color from parent */
}

.artistContainer {
  display: flex;
  gap: 10px;
}

.custom-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the parent element's color */
  /* Optionally, you can remove other link styles like hover and focus effects */
}

.detailsContainer {
  padding-left: 15px;
  padding-right: 15px;
}
.contentContainer {
  min-height: 100vh;
}

.subHeading {
  color: rgb(255, 123, 0);
  /* color: rgb(17, 169, 235); */
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

.ownerAddressText {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.dateText {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.artistImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.socialIcon {
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.subText {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.backgroundGradient {
  width: 100%;
  height: auto;
  padding-top: 110px;
}

.videodiv {
  z-index: -9;
}

.playcard {
  background: #0d0d0d;
  border-radius: 32px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.ownerIconBackground {
  background-color: white;
  width: 15px;
  height: 15px;
  margin-left: -30px;
}

.gradientColor {
  color: #a259ff;
}

.playbutton {
  font-size: 60px;
}

.songImage {
  height: 340px;
  width: 75%;
  object-fit: cover;
}

.playerColor {
  height: 4px;
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

.royaltystats {
  width: 100%;
  background: rgba(59, 59, 59, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.tabStyle {
  font-size: 25px;
  color: white;
}

.salesTab {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
}
.priceColor {
  background: linear-gradient(225deg, #54ffb3 0%, #57f2cc 47.87%, #4596fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
}

.buyBtn {
  font-size: 20px;
  /* padding-top: 0; */
  /* padding-bottom: 0; */
  height: 80px;
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 15px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 100%;
    height: auto;
  }

  .buyBtn {
    width: 320px;
  }

  .dateText {
    font-size: 14px;
    width: auto;
  }

  .songPlayer {
    width: 300px;
    height: 315px;
    margin: auto; /* This centers the iframe horizontally */
    display: flex;
    justify-content: center; /* This centers the iframe horizontally */
  }
}
</style>
