<template>
  <v-main class="downloadPage">
    <div class="hero-section">
      <v-img
        src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/background.jpg"
        gradient="to bottom, rgba(63,94,251,0.9), rgba(252,70,107,0.9)"
        class="hero-image"
        cover
      >
        <div class="hero-content">
          <v-container class="h-100 pa-0">
            <v-row class="h-100 ma-0" align="center">
              <v-col cols="12" md="6" class="pl-6">
                <div class="hero-text-container">
                  <h1 class="hero-title mb-6">
                    Experience Music<br />Investment on Mobile
                  </h1>
                  <p class="hero-subtitle mb-8">
                    Buy music digital assets, earn royalties, and track your
                    streaming revenue — all from your Android device.
                  </p>
                  <v-btn
                    size="x-large"
                    class="download-hero-btn px-8"
                    href="https://gummintbucket.s3.af-south-1.amazonaws.com/mobileapp/gummint.apk"
                    download
                  >
                    <v-icon class="mr-2">mdi-download</v-icon>
                    Download Now
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="position-relative d-none d-md-flex"
              >
                <v-img
                  src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/gummintmobile.png"
                  class="phone-screen"
                  contain
                  position="center"
                />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-img>
    </div>

    <v-container class="main-content">
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6" class="text-center">
          <h2 class="text-h3 text-white mb-3">Ready to Get Started?</h2>
          <p class="text-h6 text-grey-lighten-1 mb-12">
            Download the Gummint app and start your music investment journey
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-card class="download-card elevation-10">
            <v-card-text class="text-center py-12">
              <v-img
                src="/GUMMINT.png"
                class="mx-auto mb-6 app-icon"
                width="120"
                height="120"
              />
              <h3 class="text-h4 text-white mb-4">Gummint Mobile</h3>
              <p class="text-body-1 text-grey-lighten-1 mb-6">
                The official Gummint app for Android devices
              </p>
              <v-btn
                size="x-large"
                class="download-btn px-8 mb-4"
                href="/gummint.apk"
                download
              >
                <v-icon class="mr-2">mdi-android</v-icon>
                Download APK
              </v-btn>
              <div class="app-meta">
                <span class="text-grey">Version 1.0.0</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-16">
        <v-col
          cols="12"
          md="4"
          v-for="feature in features"
          :key="feature.title"
        >
          <div class="feature-card pa-8">
            <v-icon :color="feature.iconColor" size="56" class="mb-6">
              {{ feature.icon }}
            </v-icon>
            <h3 class="text-h5 text-white mb-4">{{ feature.title }}</h3>
            <p class="text-grey-lighten-1">{{ feature.description }}</p>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-16 mb-16">
        <v-col cols="12" md="8" lg="6">
          <v-card class="installation-card">
            <v-card-text class="pa-8">
              <h3 class="text-h5 text-white mb-6">Installation Guide</h3>
              <div class="installation-steps">
                <div
                  v-for="(step, index) in installSteps"
                  :key="index"
                  class="step-item"
                >
                  <div class="step-number">{{ index + 1 }}</div>
                  <div class="step-content">
                    <p class="text-white">{{ step }}</p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <BottomFooter class="footer" />
  </v-main>
</template>

<script>
import BottomFooter from "../components/BottomFooter.vue";

export default {
  name: "DownloadPage",
  components: {
    BottomFooter,
  },
  setup() {
    const features = [
      {
        icon: "mdi-shield-check",
        iconColor: "#4CAF50",
        title: "Secure Investment",
        description:
          "Blockchain-powered security ensuring safe and transparent transactions for all your music investments.",
      },
      {
        icon: "mdi-chart-line-variant",
        iconColor: "#FF9800",
        title: "Real-time Analytics",
        description:
          "Track your streaming royalties and earnings with detailed analytics and insights.",
      },
      {
        icon: "mdi-music-circle",
        iconColor: "#E91E63",
        title: "Music Discovery",
        description:
          "Explore and invest in upcoming artists and their music from anywhere, anytime.",
      },
    ];

    const installSteps = [
      "Download the APK file to your Android device",
      'Open Settings > Security > Enable "Install from Unknown Sources"',
      "Locate and tap the downloaded APK file",
      "Follow the installation prompts to complete setup",
      "Launch Gummint and start your music investment journey",
    ];

    return {
      features,
      installSteps,
    };
  },
};
</script>

<style scoped>
.downloadPage {
  /* background: #0D0D0D; */
}

/* Hero Section */
.hero-section {
  height: 100vh;
  position: relative;
  margin-top: -100px;
  overflow: hidden;
}

.hero-image {
  height: 100%;
}

.hero-content {
  height: 100%;
  display: flex;
  align-items: center;
}

.hero-text-container {
  max-width: 600px;
  padding-left: 16px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  color: white;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Poppins", sans-serif;
  max-width: 600px;
}

.phone-screen {
  height: 700px;
  max-width: 700px;
  object-fit: contain;
  right: -300px;
}

/* Download Buttons */
.download-btn,
.download-hero-btn {
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
  text-transform: none;
  letter-spacing: 0.5px;
  background: linear-gradient(135deg, #a259ff, #4596fb);
  border-radius: 16px;
  padding: 12px 32px;
  transition: transform 0.2s, box-shadow 0.2s;
  color: white;
}

.download-btn:hover,
.download-hero-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 32px rgba(162, 89, 255, 0.3);
}

/* Download Card */
.download-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  backdrop-filter: blur(10px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 40px;
}

.app-icon {
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  width: 120px;
  height: 120px;
  margin: 0 auto 24px auto;
}

.app-meta {
  margin-top: 16px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Feature Cards */
.feature-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.08),
    rgba(255, 255, 255, 0.02)
  );
  backdrop-filter: blur(10px);
  border-radius: 24px;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 32px;
}

.feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.feature-card .v-icon {
  margin-bottom: 24px;
  font-size: 48px;
}

.feature-card h3 {
  color: white;
  font-family: "Poppins", sans-serif;
  margin-bottom: 16px;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

/* Installation Card */
.installation-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.08),
    rgba(255, 255, 255, 0.02)
  );
  backdrop-filter: blur(10px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 32px;
}

.installation-steps {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.step-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.step-number {
  width: 32px;
  height: 32px;
  background: linear-gradient(135deg, #a259ff, #4596fb);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  flex-shrink: 0;
}

.step-content {
  flex: 1;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Poppins", sans-serif;
}

/* Main Content Section */
.main-content {
  padding-top: 64px;
  padding-bottom: 64px;
}

.section-title {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 16px;
  text-align: center;
}

.section-subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 48px auto;
}

/* Footer */
.footer {
  padding: 24px 0;
  color: white;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 960px) {
  .hero-text-container {
    padding: 0 24px;
    text-align: center;
    margin: 0 auto;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
    margin: 0 auto;
  }

  .phone-screen {
    height: 500px;
  }

  .section-title {
    font-size: 2rem;
  }

  .feature-card {
    margin-bottom: 24px;
  }
}

@media (max-width: 600px) {
  .hero-section {
    height: auto;
    min-height: 100vh;
  }

  .hero-text-container {
    padding: 32px 16px;
  }

  .phone-screen {
    height: 400px;
  }

  .download-btn,
  .download-hero-btn {
    width: 100%;
    margin-bottom: 16px;
  }

  .feature-card {
    padding: 24px;
  }

  .installation-card {
    padding: 24px;
  }

  .step-item {
    gap: 12px;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }
  .main-content {
    margin-top: -300px;
  }
}

/* Utility Classes */
.text-gradient {
  background: linear-gradient(135deg, #a259ff, #4596fb);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.glassmorphism {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.elevation-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.elevation-hover:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}
</style>
