<template>
  <v-main>
    <div class="mainContainer">


      <div class="detailsContainer">
          <div class="mx-auto text-center">
            <!-- Embedded YouTube video -->
            <iframe
              class="songPlayer"
              src="https://www.youtube.com/embed/5qFr76kgALQ"
              title="Enter the metaverse"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

          </div>
          <p class="titleText">Enter the metaverse</p>
          <p class="subtitleText mt-5">
            Available digital asset editions to purchase from Enter the metaverse song, each representing a percentage of the streaming royalty shares of the song
          </p>
        </div>

      <!-- Loading State (initial load) -->
      <div v-if="loading && nfts.length === 0" class="mainProgressCircle">
        <v-progress-circular
          indeterminate
          color="rgb(255, 123, 0)"
          :size="50"
        />
      </div>

      <!-- Error State -->
      <div v-if="error" class="text-center mt-4">
        <v-alert type="error" outlined>{{ error }}</v-alert>
      </div>



      <!-- NFT Display -->
      <div v-if="nfts.length > 0">
        <!-- NFT Grid -->
        <div class="nftcollection-grid mt-4">
          <NFTCard
            v-for="nft in nfts"
            :key="nft.tokenId"
            :nftData="mapNFTData(nft)"
            :collectionId="route.params.id"
            class="my-2"
          />
        </div>

        <!-- Load More Button -->
        <div class="text-center my-6">
          <v-btn
            v-if="hasMore"
            color="primary"
            :loading="loading"
            @click="fetchNFTBatch"
            class="load-more-btn"
          >
            Load More NFTs
          </v-btn>

          <!-- Loading indicator for additional NFTs -->
          <v-progress-circular
            v-if="loading && nfts.length > 0"
            indeterminate
            color="rgb(255, 123, 0)"
            :size="30"
            class="ml-3"
          />
        </div>
      </div>
    </div>

    <BottomFooter class="footer" />
  </v-main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { ethers } from "ethers";
import CreateNft from "../CreateNft.json";
import Marketplace from "../Marketplace.json";
import NFTCard from "../components/NFTCard.vue";
import BottomFooter from "../components/BottomFooter.vue";

export default {
  name: "CollectionNFTPage",
  components: {
    NFTCard,
    BottomFooter,
  },

  setup() {
    const route = useRoute();
    const loading = ref(false);
    const error = ref(null);
    const nfts = ref([]);
    
    // Pagination state
    const currentTokenId = ref(1);
    const batchSize = 15;
    const hasMore = ref(true);
    const totalTokens = ref(0);

    const fetchNFTBatch = async () => {
      if (!hasMore.value || loading.value) return;

      try {
        loading.value = true;
        const provider = new ethers.JsonRpcProvider(process.env.VUE_APP_RPC_URL);

        let createNft = JSON.parse(CreateNft);
        let market = JSON.parse(Marketplace);

        const nftContract = new ethers.Contract(
          createNft.address,
          createNft.abi,
          provider
        );

        const marketContract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );

        // Get total tokens if first batch
        if (currentTokenId.value === 1) {
          const counter = await nftContract.getTokenCounter();
          totalTokens.value = Number(counter.toString());
          console.log("Total tokens:", totalTokens.value);

          if (totalTokens.value === 0) {
            hasMore.value = false;
            loading.value = false;
            return;
          }
        }

        const newNFTs = [];
        const endId = Math.min(
          currentTokenId.value + batchSize - 1,
          totalTokens.value
        );

        console.log(`Fetching tokens ${currentTokenId.value} to ${endId}`);

        for (let i = currentTokenId.value; i <= endId; i++) {
          try {
            const tokenURI = await nftContract.tokenURI(i);
            const marketListing = await marketContract.getListing(i);

            const response = await fetch(tokenURI);
            const metadata = await response.json();

            newNFTs.push({
              ...metadata,
              tokenId: i,
              marketData: {
                price: marketListing.price.toString(),
                seller: marketListing.seller,
                isListed: marketListing.listedForSale,
                holder: marketListing.holder,
              },
            });
          } catch (err) {
            console.log(`Skipping token ${i}:`, err.message);
            continue;
          }
        }

        // Add new NFTs to existing ones
        nfts.value = [...nfts.value, ...newNFTs];
        console.log(`Loaded ${newNFTs.length} new NFTs`);

        // Update pagination state
        currentTokenId.value = endId + 1;
        hasMore.value = currentTokenId.value <= totalTokens.value;
      } catch (err) {
        console.error("Error fetching NFTs:", err);
        error.value = "Failed to load NFTs";
      } finally {
        loading.value = false;
      }
    };

    const mapNFTData = (nftData) => {
      return {
        tokenId: nftData.tokenId,
        name: nftData.name,
        image: nftData.image,
        artist1: nftData.artist1,
        royaltyType: nftData.royaltyType || "Streaming",
        royaltyPercentage: nftData.royaltyPercentage || "0.1",
        marketData: {
          price: nftData.marketData?.price || "0",
          seller: nftData.marketData?.seller || "",
          isListed: nftData.marketData?.isListed || false,
          holder: nftData.marketData?.holder || "",
        },
      };
    };

    onMounted(() => {
      fetchNFTBatch();
    });

    return {
      nfts,
      loading,
      error,
      fetchNFTBatch,
      hasMore,
      route,
      mapNFTData,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");
.mainContainer {
  min-height: 100vh;
}

.titleText {
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: rgb(255, 123, 0);
  position: relative;
  z-index: 1;
}

.subtitleText {
  font-size: 16px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: rgb(240, 240, 240);
  position: relative;
  z-index: 1;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.detailsContainer {
  margin-top: 60px;
  text-align: center;
  z-index: 10;
}

.songPlayer {
  width: 560px;
  height: 315px;
}

.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  width: 90%;
  margin-inline: auto;
}

.error-container {
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

@media screen and (min-width: 370px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.footer {
  margin-top: 2rem;
}

.load-more-btn {
  min-width: 150px;
}

@media screen and (max-width: 600px) {
  .titleText {
    font-size: 40px;
  }
  .subtitleText {
    font-size: 14px;
  }
  .detailsContainer {
    margin-top: 0px;
  }

  .songPlayer {
    width: 300px;
    height: 315px;
  }
}
</style>