<template>
  <v-card max-height="500" max-width="300" class="playcard elevation">
    <img
      class="songImage"
      :src="imageUrl"
      alt="logo"
    />
    <div class="text-center">
      <p class="text-white text-h6">{{songDetails.name}}</p>
      <div class="">
        <div class="text-grey">{{songDetails.artist.name}}</div>
      </div>
      <!-- <p class="text-white "></p> -->
      <div class="mt-3 mx-4 playerColor"></div>
      <!-- <audio ref="audio" controls>
        <source :src="audioSource" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio> -->

      <div class="d-flex flex-row justify-space-around align-center my-4 mx-10">
        <v-icon small class="gradientColor">mdi-rewind</v-icon>
        <v-icon class="gradientColor playbutton" @click="openDialog"
          >mdi-play-circle</v-icon
        >
        <v-icon class="gradientColor">mdi-fast-forward</v-icon>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="400">
    <v-card class="rounded-xl stream-dialog">
      <v-card-title class="text-h5 text-center pa-4">
        Listen to "{{songDetails.name}}"
      </v-card-title>
      <v-card-text class="pa-4">
        <!-- <p class="text-center mb-6">
          Play on Youtube
        </p> -->
        <div class="d-flex flex-column align-center">
          <iframe
              class="songPlayer"
             
              :src="songLink"
              :title="songDetails.name"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
        </div>
        <v-divider class="my-6"></v-divider>
        <!-- <div class="text-center">
          <v-btn @click="viewArtist" color="primary" text rounded>
            View Artist Profile
          </v-btn>
        </div> -->
      </v-card-text>
    </v-card>
  </v-dialog>
  </v-card>
</template>

<script>
import { ref } from "vue";
import router from "../router"; // Adjust the import path as needed

export default {
  name: "PlayCard",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const songDetails = ref(props.card.collection)
    let imageUrl = songDetails.value.image;
    const songLink = songDetails.value.link;
    const streamingPlatforms = [
      // {
      //   name: "Spotify",
      //   icon: "mdi-spotify",
      //   url: `https://open.spotify.com/search/${encodeURIComponent(
      //     props.card.title + " " + props.card.artist
      //   )}`,
      // },
      // {
      //   name: "Apple Music",
      //   icon: "mdi-apple",
      //   url: `https://music.apple.com/search?term=${encodeURIComponent(
      //     props.card.title + " " + props.card.artist
      //   )}`,
      // },
      // {
      //   name: "YouTube Music",
      //   icon: "mdi-youtube",
      //   url: `https://music.youtube.com/search?q=${encodeURIComponent(
      //     props.card.title + " " + props.card.artist
      //   )}`,
      // },
      // {
      //   name: "Deezer",
      //   icon: "mdi-music",
      //   url: `https://www.deezer.com/search/${encodeURIComponent(
      //     props.card.title + " " + props.card.artist
      //   )}`,
      // },
    ];

    const openDialog = () => {
      dialog.value = true;
    };

    const viewArtist = () => {
      // Assuming you have a route for artist profiles
      router.push({
        name: "Artist",
        params: { id: songDetails.value.artist.id },
      });
    };

    return {
      dialog,
      streamingPlatforms,
      openDialog,
      viewArtist,
      songDetails,
      imageUrl,
      songLink
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.scrolling-text-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.songPlayer{
  width: 100%;
  height: 100%;
}

.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}

.scrolling-text {
  display: inline-block;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.playcard {
  background: #0d0d0d;

  /* border-color: #8743ff; */
  border-color: #797979;
  border-width: 1px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(15px);
  animation-name: floatLogo;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.elevation {
  /* Set initial box shadow and transform values */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.elevation:hover {
  /* Change box shadow and transform values on hover */
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}

.playbutton {
  font-size: 60px;
}

.songImage {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .playbutton {
    font-size: 40px;
  }

  .songImage {
    /* border-radius: 25px; */
    height: 150px;
    width: 100%;
  }
}

.gradientColor {
  color: #11a9eb;
}

.playerColor {
  height: 2px;
  background: linear-gradient(90deg, #4136f1 -0.68%, #8743ff 100.7%);
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 300px;
  }
  /* .songImage {
    height: 260px;
    width: 100%;
  } */
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 50vw;
    height: auto;
    max-width: 100px;
  }

  .songImage {
    height: 23vh;
    width: 100%;
  }

  .subText {
    font-size: 12px;
  }

  .titleText {
    font-size: 16px;
  }

  .btn {
    font-size: 13px;
  }
}

.stream-dialog {
  border-color: #797979;
  border-width: 1px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: #0d0d0d;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
  color: white;
}

.v-card-title {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.stream-btn {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  height: 48px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.stream-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.v-btn {
  text-transform: none;
}

@media (max-width: 600px) {
  .stream-dialog {
    margin: 16px;
    max-width: calc(100% - 32px);
  }

  .v-card-title {
    font-size: 1.25rem;
  }

  .stream-btn {
    width: 100%;
    max-width: 280px;
  }
}
</style>
