<template>
  <v-card max-height="450" max-width="250" class="playcard elevation">
    <div v-if="loading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <router-link :to="`/collection/${collectionData.id}`" class="router-link">
        <img class="songImage" :src="image" alt="logo" />
        <div class="pl-4 pr-1 pb-2" style="width: 100%">
          <div class="d-flex mt-1" style="gap: 5px">
            <img class="artistImage" :src="artistImage" alt="logo" />
            <p class="text-white subText mt-1" style="opacity: 0.6">
              {{ artist.name }}
            </p>
          </div>
          <p class="text-white titleText truncate">{{ collectionData.name }}</p>
          <!-- <p class="text-white subText" style="opacity: 0.6">Share: 5%</p> -->
          <!-- <p class="text-white subText" style="opacity: 0.6">Terms: 30 years</p> -->
        </div>
        <v-card-actions class="button-container">
          <v-btn
            variant="outlined"
            color="blue"
            class="text-center mx-auto mb-1"
            >View Assets</v-btn
          >
        </v-card-actions>
      </router-link>
    </div>
  </v-card>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import router from "../router";

export default {
  name: "CollectionCard",
  props: {
    collectionData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const collectionData = ref(props.collectionData);
    const artist = ref({
      id: "1",
      name: "Gummint",
      user: {
        profilePicture: "/GUMMINT.png",
      },
    });

    const artistImage = ref("/GUMMINT.png");
    const loading = ref(false);
    const image = ref(collectionData.value.image);

    return {
      collectionData,
      image,
      loading,
      artist,
      artistImage,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.elevation {
  /* Set initial box shadow and transform values */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px; /* 100% of the viewport height */
  width: 250px;
}

.button-container {
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.router-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from parent */
  cursor: pointer; /* Show pointer cursor on hover */
}

.elevation:hover {
  /* Change box shadow and transform values on hover */
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}
.playcard {
  height: 360px;
  background: #0d0d0d;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.subText {
  font-size: 15px;
}

.songImage {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.titleText {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.gradientColor {
  color: #57f2cc;
}
.btn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;
  color: white;
  /* box-shadow: 0 0 2px #eee; */
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.playbutton {
  font-size: 60px;
}

.artistImage {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.playerColor {
  height: 2px;
  background: linear-gradient(90deg, #4136f1 -0.68%, #8743ff 100.7%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 170px;
    height: 290px;
  }

  .mainProgressCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px; /* 100% of the viewport height */
    width: 170px;
  }

  .songImage {
    height: 160px;
    width: 100%;
  }

  .subText {
    font-size: 12px;
  }

  .titleText {
    font-size: 13px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 250px;
  }
}

/* .title {
  font-size: 30px;
} */
</style>
